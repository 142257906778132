import React, {useState, useEffect} from 'react';
import {BrowserRouter as Router, Routes, Route, Link, Switch} from 'react-router-dom';

import {ConnectionContex} from './ConnectionSystem';

function PageFooter() {
	const [scrollVisible, setScrollvisible] = useState(false);

	const toggleScrollView = () => {
		if (window.pageYOffset > 225) {
			setScrollvisible(true);
		} else {
			setScrollvisible(false);
		}
	};

	const naviguerEnHaut = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	useEffect(() => {
		window.addEventListener('scroll', toggleScrollView);

		return () => {
			window.removeEventListener('scroll', toggleScrollView);
		};
	}, []);

	return (
		<footer>
			<div className="w-full bg-white text-secondary flex flex-col">
				<div className='w-full flex justify-center items-center mt-28 border-b border-gray-700 pb-3'>
					<p className='text-sm'>
						© Copyright Juillet 2024
					</p>
				</div>
				<div className="w-full flex flex-wrap px-6 mt-12 justify-center">
					<div className="xl:w-4/12 md:w-5/12 sm:w-full px-4">
						<span className="bg-transparent text-xl border-b pb-2 pr-3"> A propos </span>
						<div className="mt-6 text-justify">
							Site web de vente en ligne. Le Président du mouvement NRP (Nouvelle Révolution Panafricaine) met à votre disponibilité une gamme d'article en vente. 
							<hr className="mr-24 mt-8 pt-2"/>
							<div className="w-full flex flex-col">
								<div className="flex">
									<svg className="w-4 h-4 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
										<path d="M2.038 5.61A2.01 2.01 0 0 0 2 6v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6c0-.12-.01-.238-.03-.352l-.866.65-7.89 6.032a2 2 0 0 1-2.429 0L2.884 6.288l-.846-.677Z"/>
										<path d="M20.677 4.117A1.996 1.996 0 0 0 20 4H4c-.225 0-.44.037-.642.105l.758.607L12 10.742 19.9 4.7l.777-.583Z"/>
									</svg>
									info@hermannlepatriote.com
								</div>
								<div className="flex">
									<svg className="w-4 h-4 mr-2"  aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
										<path d="M7.978 4a2.553 2.553 0 0 0-1.926.877C4.233 6.7 3.699 8.751 4.153 10.814c.44 1.995 1.778 3.893 3.456 5.572 1.68 1.679 3.577 3.018 5.57 3.459 2.062.456 4.115-.073 5.94-1.885a2.556 2.556 0 0 0 .001-3.861l-1.21-1.21a2.689 2.689 0 0 0-3.802 0l-.617.618a.806.806 0 0 1-1.14 0l-1.854-1.855a.807.807 0 0 1 0-1.14l.618-.62a2.692 2.692 0 0 0 0-3.803l-1.21-1.211A2.555 2.555 0 0 0 7.978 4Z"/>
									</svg>
									+1 949 357 3684
								</div>								
							</div>
						</div>
					</div>
				</div>
				<div className="w-full border-gray-500 py-3 flex justify-center mt-4">
					<div className="w-auto flex flex-wrap">
						<Link to="https://www.instagram.com/hermann_le_patriote" target="_blank">
							<svg className="w-6 h-6 mx-1 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
								<path fill="currentColor" fill-rule="evenodd" d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z" clip-rule="evenodd"/>
							</svg>
						</Link>
						<Link to="https://www.facebook.com/lePatrioteHermann?mibextid=LQQJ4d" target="_blank">
							<svg className="w-6 h-6  mx-1 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
								<path fill-rule="evenodd" d="M13.135 6H15V3h-1.865a4.147 4.147 0 0 0-4.142 4.142V9H7v3h2v9.938h3V12h2.021l.592-3H12V6.591A.6.6 0 0 1 12.592 6h.543Z" clip-rule="evenodd"/>
							</svg>
						</Link>
						<Link to="https://m.youtube.com/c/HermannIvoirienUsa" target="_blank">
							<svg className="w-6 h-6 mx-1 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
								<path fill-rule="evenodd" d="M21.7 8.037a4.26 4.26 0 0 0-.789-1.964 2.84 2.84 0 0 0-1.984-.839c-2.767-.2-6.926-.2-6.926-.2s-4.157 0-6.928.2a2.836 2.836 0 0 0-1.983.839 4.225 4.225 0 0 0-.79 1.965 30.146 30.146 0 0 0-.2 3.206v1.5a30.12 30.12 0 0 0 .2 3.206c.094.712.364 1.39.784 1.972.604.536 1.38.837 2.187.848 1.583.151 6.731.2 6.731.2s4.161 0 6.928-.2a2.844 2.844 0 0 0 1.985-.84 4.27 4.27 0 0 0 .787-1.965 30.12 30.12 0 0 0 .2-3.206v-1.516a30.672 30.672 0 0 0-.202-3.206Zm-11.692 6.554v-5.62l5.4 2.819-5.4 2.801Z" clip-rule="evenodd"/>
							</svg>								
						</Link>
					</div>
				</div>
				{
					scrollVisible && (
						<button className="naviguerAuTop" onClick={() => naviguerEnHaut()}>
							<svg class="w-[36px] h-[36px] text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
							<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 9h13a5 5 0 0 1 0 10H7M3 9l4-4M3 9l4 4"/>
							</svg>
						</button>
					)
				}
				
			</div>
		</footer>
	);
}

export default PageFooter;

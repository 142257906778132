import React, {useState, useRef, Component, useEffect, useContext} from 'react';
import {Link} from 'react-router-dom';
import Slider from 'react-slick';
import axios from 'axios';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {ConnectionContext} from './ConnectionSystem';

const ModalVoirBoutique = ({panierId, boutik, nomProd, imagePrincipale, image1, image2, image3, image4, coutProd, infoProd, serieProd, boutikType, closeBoutik}) => {
	const {hermannlepatrioteClientlogin, hermannlepatrioteClientlogout, hermannlepatrioteClientconnecte, formatDateFr} = useContext(ConnectionContext);
	const [boutikProduit, setBoutikproduit] = useState(false);
	const [visuelProduit, setVisuelproduit] = useState("`${imagePrincipale}`");
	const [messageSuccess, setMessageSuccess] = useState(true);
	const [hlpPanierTemp, setHlppaniertemp] = useState(panierId);
	const [produitTaille, setProduittaille] = useState('');
	//
	useEffect(() => {
		if (document.getElementById('visuel-settings-default').classList.contains('hideitem')) {
			document.getElementById('visuel-settings-default').classList.remove('hideitem');
			document.getElementById('visuel-settings').classList.add('hideitem');
		}
		if (!boutik) {
			setVisuelproduit("");
		}
	}, [boutik]);
	const settingsProduit3 = {
		dots: false,
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 1,
		vertical: true,
		verticalSwiping: true,
		arrows: false,
		autoplay: true,
		adaptativeHeight: true,
    	speed: 1500,
    	autoplaySpeed: 6500,
    	pauseOnHover: true,
	};
	const insertProduitIntoCart = async(i, n, im, c) => {
		var achatDate = formatDateFr(new Date());
		var p = panierId;
		axios.post('https://api.hermannlepatriote.com/_010111SQL_/insertionachathorsligne', {p, i, n, im, c, achatDate})
		.then(response => {
			switch(response.data.erreur) {
				case 'ErreurAchatCheck1':
					return alert(response.data.message);
				case 'AchatError':
					return alert(response.data.message);
				case 'PanierError':
					return alert(response.data.message);
				case 'PanierAchatSuccess1':
					return ajoutBoutique();
				case 'PanierAchatSuccess2':
					return ajoutBoutique();
				default:
					return null;
			}
			return;
		})
		.catch(error => {
			console.error('Erreur achat article !', error);
		});
	};
	function getVisuelproduit(p) {
		setVisuelproduit(p);
		document.getElementById('visuel-settings-default').classList.add('hideitem');
		document.getElementById('visuel-settings').classList.remove('hideitem');
	}
	function achatBoutique(p, n, i, c) {
		insertProduitIntoCart(p, n, i, c);	
	}
	function ajoutBoutique() {
		document.getElementById('transition-effect').classList.remove('hidden');
		const timer = setTimeout(() => {
			document.getElementById('transition-effect').classList.add('hidden');
			setMessageSuccess(false);
		}, 3000);
		return () => clearTimeout(timer);
	}
	return (
		<div className={`${boutik ? 'modal-show' : 'modal'}`}>
			<div className="modal-boutik-content shadow-xl">
				<p className="text-2xl border-b border-gray-200 py-2 px-3">
					{nomProd}
					<span className="close-button" onClick={closeBoutik}>&times;</span>
				</p>
				<div className="flex flex-wrap lg:justify-between md:justify-center p-5">
					<div className="w-full flex flex-wrap md:justify-center" id="page-boutique-view1">
						<div className="lg:w-4/6 md:max-w-xl">
							<div className="w-full flex flex-wrap items-center px-12 pt-12">
								<img className="h-auto" id="visuel-settings-default" src={`${imagePrincipale}`} alt="image" />
							</div>
						</div>
						<div className="lg:w-2/6 md:max-w-xl" id="page-boutique-div2">
							<span className="underline text-md">Description:</span>
							<div className="mt-5">
								<div dangerouslySetInnerHTML={{ __html: infoProd}} />
							</div>
							<div className={boutikType == 1 ? 'w-full flex flex-wrap my-5' : 'hidden'}>
								<span className="border-gray-200 border rounded-2xl px-2 py-1 mr-2 cursor-pointer">S</span>
								<span className="border-gray-200 border rounded-2xl px-2 py-1 mr-2  cursor-pointer">M</span>
								<span className="border-gray-200 border rounded-2xl px-2 py-1 mr-2 cursor-pointer">L</span>
								<span className="border-gray-200 border rounded-2xl px-2 py-1 mr-2 cursor-pointer">XL</span>
							</div>	
							<div className="w-full flex flex-wrap my-5">
								<span className="text-3xl font-bold">$ {coutProd}</span>
							</div>
							<div className="w-full flex flex-wrap mt-5 bg-green-200 justify-center py-1 hidden" id="transition-effect">
								Ce produit est ajouté à votre panier !
							</div>												
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ModalVoirBoutique;
